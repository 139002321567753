import React from "react";
import { twMerge } from "tailwind-merge";
import CardLink from "../../UI/Card/CardLink";
import ListButtonGroup from "../../UI/ListButtonGroup/ListButtonGroup";
import ListButtonGroupItem from "../../UI/ListButtonGroup/ListButtonGroupItem";
import { fullName } from "../../../utils/fullName";
import BackLink from "../../Mobile/BackLink";
import StudentEnrollmentsMobile from "../../UI/StudentEnrollmentsMobile";

export default function StudentsMobileList({
  students,
  tableNavigation,
  handleSection,
  section,
  loading,
}) {
  return (
    <>
      <BackLink path="/" label="Students" />

      <ListButtonGroup className="block relative mt-2">
        {tableNavigation.map((item, index) => (
          <ListButtonGroupItem
            key={item.name}
            label={item.label}
            name={item.name}
            isFirst={index === 0}
            isLast={index === tableNavigation.length - 1}
            setSelected={handleSection}
            selected={section}
          />
        ))}
      </ListButtonGroup>

      <div className={twMerge("pt-4", loading && "opacity-50")}>
        {students.map((student) => (
          <CardLink
            key={student.id}
            to={`/students/${student.id}/overview`}
            className="mb-4 last:mb-0 block"
            cardClassName="rounded-xl p-0 pb-2"
          >
            <span
              className={twMerge(
                "flex p-2 justify-between",
                student.enrollments.length > 0 && "border-b border-gray-500/10"
              )}
            >
              <span className="text-lg p-2 pb-0">
                {fullName(student.first_name, " ", student.last_name)}
              </span>
            </span>

            <StudentEnrollmentsMobile student={student} />
          </CardLink>
        ))}
      </div>
    </>
  );
}
