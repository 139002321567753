import React, { useState, useEffect } from "react";
import { Pagination, usePagination } from "../UI/Pagination";
import { useOutletContext, useSearchParams } from "react-router-dom";
import {
  getAllStudents,
  downloadCsvStudentsRequest,
} from "../../requests/students";
import Card from "../UI/Card";
import QuickFind from "../UI/QuickFind";
import ListButtonGroup from "../UI/ListButtonGroup/ListButtonGroup";
import ListButtonGroupItem from "../UI/ListButtonGroup/ListButtonGroupItem";
import StudentsMobileList from "./Mobile/StudentsMobileList";
import Modal from "../UI/Modal";
import { ToastNotification } from "../UI/ToastNotification";
import PrintTicket from "./Student/PrintTicket";
import { checkPermission } from "../../utils/checkPermission";
import { useMediaQuery } from "../../utils/useMediaQuery";
import { usePageActions } from "../../contexts/PageActionsContext";
import {
  ReassignIcon,
  ShareIcon,
  CancelSharingIcon,
  UploadCSVIcon,
  DownloadCSVIcon,
  PrintIcon,
} from "../UI/PageActions/Icons";
import ReassignStudents from "./Actions/ReassignStudents";
import ShareStudents from "./Actions/ShareStudents";
import UnshareStudents from "./Actions/UnshareStudents";
import { displayError } from "../../utils/displayError";
import { useFilterValues } from "../UI/Filter/Filter";
import StudentsFilter from "./StudentsFilter";
import StudentsPrint from "./StudentsPrint";
import StudentsTable from "./StudentsTable";
import CsvImport from "./CsvImport/CsvImport";

export default function Students() {
  const isAdmin = checkPermission(["school_admin", "conference_admin"]);
  const isMobile = useMediaQuery("md", "down");
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const pagination = usePagination();
  const { setPageActions } = usePageActions();
  const filterValues = useFilterValues();
  const { setActionMenuModal } = useOutletContext();
  const section = searchParams.get("section") || "all";
  const page = searchParams.get("page") || "1";
  const studentsQuery = searchParams.get("studentsQuery");
  const query = searchParams.get("query") || studentsQuery || "";
  const sortBy = searchParams.get("sortBy") || "created_at";
  const orderBy = searchParams.get("orderBy") || "desc";
  const [showCsvUpload, setShowCsvUpload] = useState(false);
  const [enableBulkActions, setEnableBulkActions] = useState(false);
  const [printTicketModal, setPrintTicketModal] = useState({
    open: false,
    student: null,
  });
  const tableNavigation = [
    { label: "All", name: "all" },
    { label: "Interests", name: "interests" },
    { label: "Enrolled", name: "enrolled" },
  ];
  const [students, setStudents] = useState([]);
  const [refreshStudents, setRefreshStudents] = useState(false);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [reassignStudentsOpen, setReassignStudentsOpen] = useState(false);
  const [shareStudentsOpen, setShareStudentsOpen] = useState(false);
  const [unshareStudentsOpen, setUnshareStudentsOpen] = useState(false);
  const [downloadingCsv, setDownloadingCsv] = useState(false);
  const [isPrinting, setIsPrinting] = useState(false);
  const [printingStudents, setPrintingStudents] = useState([]);

  const getParams = ({ print = false, csv = false } = {}) => {
    const queryParams = {
      section: section,
      page: csv || print ? undefined : page,
      per_page: print || csv ? undefined : pagination.recordsPerPage,
      pagination: !print && !csv,
      q: { ...filterValues, sorts: `${sortBy} ${orderBy}` },
    };

    // By default we should filter by active enrollments.
    // In order to allow the use of `queryParams.q.enrollments_active` by the filters,
    // we need to set a different key with the value `true` that will serve as fallback in backend.
    queryParams.default_only_active_enrollments = true;

    // Only add the search parameter if query is not empty.
    // Also using Ransacker gem Search Matcher `*_i_cont_all` pattern.
    // Splitting the query terms into an array for the `cont` matcher.
    if (query.trim()) {
      const attributes = [
        "full_name",
        "country",
        "state",
        "city",
        "zip_code",
        "address",
        "address_2",
        "enrollments_registration_number",
        "school_church_name",
        "school_school_code",
      ];
      queryParams.q[`${attributes.join("_or_")}_i_cont_all`] = query
        .trim()
        .split(" ");
    }

    return queryParams;
  };

  const getStudents = async (callback) => {
    setLoading(true);
    setSelectedStudents([]);

    try {
      const res = await getAllStudents({
        params: getParams({ print: Boolean(callback) }),
      });

      if (res.data) {
        if (callback) {
          callback(res.data.students);
        } else {
          setStudents(res.data.students);
          pagination.setData(res.data.pagination);
          window.scrollTo(0, 0);
        }
      }
    } catch (error) {
      displayError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSection = (section) => {
    setSearchParams((prev) => {
      prev.set("section", section);
      prev.set("page", "1");
      prev.set("query", "");
      return prev;
    });
  };

  const onSubmit = async (data) => {
    setSearchParams((prev) => {
      prev.set("section", section);
      prev.set("page", "1");
      prev.set("query", data.search || "");
      return prev;
    });
  };

  const handleDownloadCsv = async () => {
    setDownloadingCsv(true);
    const res = await downloadCsvStudentsRequest({
      params: getParams({ csv: true }),
    });

    if (!res.success) {
      ToastNotification("error", "Failed to download CSV");
    }

    setDownloadingCsv(false);
  };

  useEffect(() => {
    if (refreshStudents) {
      getStudents();
      setRefreshStudents(false);
    }
  }, [refreshStudents]);

  useEffect(() => {
    setEnableBulkActions(selectedStudents.length > 0);
  }, [selectedStudents]);

  useEffect(() => {
    setShowCsvUpload(false);
  }, [enableBulkActions]);

  // Fetch students when the component mounts
  // and when pagination or section changes.
  useEffect(() => {
    getStudents();
  }, [searchParams, pagination.recordsPerPage]);

  useEffect(() => {
    setPageActions([
      {
        icon: ReassignIcon,
        label: "Reassign Students",
        disabled: !enableBulkActions,
        onClick: () => {
          setReassignStudentsOpen(true);
        },
      },
      {
        icon: ShareIcon,
        label: "Share Students",
        disabled: !enableBulkActions,
        onClick: () => {
          setShareStudentsOpen(true);
        },
      },
      {
        icon: CancelSharingIcon,
        label: "Cancel Sharing",
        disabled: !enableBulkActions,
        onClick: () => {
          setUnshareStudentsOpen(true);
        },
      },
      {
        icon: UploadCSVIcon,
        label: "Upload CSV",
        disabled: !isAdmin,
        onClick: () => {
          setShowCsvUpload((prev) => !prev);
        },
      },
      {
        icon: DownloadCSVIcon,
        disabled: loading || downloadingCsv || !students?.length,
        loading: downloadingCsv,
        label: "Download CSV",
        onClick: handleDownloadCsv,
      },
      {
        icon: PrintIcon,
        label: "Print Records",
        disabled: loading || isPrinting || !students?.length,
        loading: isPrinting,
        onClick: () => {
          setIsPrinting(true);

          getStudents((students) => {
            setPrintingStudents(students);
            setIsPrinting(false);
            setLoading(false);
          });
        },
      },
    ]);

    return () => {
      setPageActions([]);
    };
  }, [
    setPageActions,
    enableBulkActions,
    downloadingCsv,
    searchParams,
    students,
    loading,
    isPrinting,
  ]);

  useEffect(() => {
    if (printingStudents.length > 0) window.print();

    return () => {
      if (printingStudents.length > 0) setPrintingStudents([]);
    };
  }, [printingStudents]);

  return isMobile ? (
    <>
      <StudentsMobileList
        students={students}
        tableNavigation={tableNavigation}
        handleSection={handleSection}
        section={section}
        loading={loading}
      />

      <Pagination
        pagination={pagination}
        loading={loading && !students?.length}
        empty={!students?.length}
      />
    </>
  ) : (
    <>
      {showCsvUpload && (
        <CsvImport
          setShowCsvUpload={setShowCsvUpload}
          setRefreshStudents={setRefreshStudents}
        />
      )}

      <Card>
        <div className="flex items-center justify-between mb-6">
          <div className="relative">
            {isAdmin && (
              <img
                src="/add-new.svg"
                className="absolute left-0 top-1/2 mt-0 h-auto max-w-full -translate-x-10 -translate-y-1/2 transform cursor-pointer"
                width={30}
                height={30}
                alt="Add New"
                onClick={() =>
                  setActionMenuModal({
                    open: true,
                    defaultRender: "NewStudent",
                  })
                }
              />
            )}

            <h1 className="text-xl font-bold text-primary">STUDENTS</h1>
          </div>
          <ListButtonGroup>
            {tableNavigation.map((item, index) => (
              <ListButtonGroupItem
                key={item.name}
                label={item.label}
                name={item.name}
                isFirst={index === 0}
                isLast={index === tableNavigation.length - 1}
                setSelected={handleSection}
                selected={section}
              />
            ))}
          </ListButtonGroup>
          <div className="flex relative">
            <QuickFind onSubmit={onSubmit} value={query} />
            <div className="relative ml-7">
              <StudentsFilter />
            </div>
          </div>
        </div>
        <Pagination
          pagination={pagination}
          loading={loading && !students?.length}
          empty={!students?.length}
        >
          <StudentsTable
            students={students}
            loading={loading}
            selectedStudents={selectedStudents}
            setSelectedStudents={setSelectedStudents}
            setPrintTicketModal={setPrintTicketModal}
          />
        </Pagination>
      </Card>

      <Modal
        open={printTicketModal.open}
        setOpen={() => setPrintTicketModal({ open: false, student: null })}
      >
        <PrintTicket
          student={printTicketModal.student}
          closeModal={() => setPrintTicketModal({ open: false, student: null })}
        />
      </Modal>

      <ReassignStudents
        open={reassignStudentsOpen}
        students={selectedStudents}
        setOpen={setReassignStudentsOpen}
        onSuccess={getStudents}
      />

      <ShareStudents
        open={shareStudentsOpen}
        students={selectedStudents}
        setOpen={setShareStudentsOpen}
        onSuccess={getStudents}
      />

      <UnshareStudents
        open={unshareStudentsOpen}
        students={selectedStudents}
        setOpen={setUnshareStudentsOpen}
        onSuccess={getStudents}
      />

      <StudentsPrint students={printingStudents} />
    </>
  );
}
