import Button from "../UI/Button";
import React, { useEffect, useState, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import MultiSelect from "../UI/Inputs/MultiSelect";
import { getAllSchoolsSimpleList, getAllSchools } from "../../requests/schools";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default function PrintSchoolEnvelopesReport() {
  const defaultValues = useMemo(
    () => ({
      schools_ids: [],
    }),
    []
  );

  const methods = useForm({ defaultValues });
  const [searchParams, setSearchParams] = useSearchParams();
  const [schools, setSchools] = useState([]);
  const [selectedSchools, setSelectedSchools] = useState();
  let data = [];

  const docDefinition = () => {
    data = [];
    let row = [];

    selectedSchools.forEach((school, index) => {
      const line = {
        text: [
          `${school.school_name} (#${school.school_code})`,
          school.head_school_admin
            ? `Attention: ${school.head_school_admin?.full_name}`
            : null,
          school.school_address ? school.school_address : "N/A",
          `${school.school_city}, ${school.school_state} ${school.school_zip_code}`,
        ]
          .filter(Boolean)
          .join("\n"),
        fontSize: 12,
        margin: [0, 0, 0, 0],
      };

      row.push(line);

      data.push(row);
      row = [];

      if (index < selectedSchools.length - 1) {
        data.push({ text: "", pageBreak: "after" });
      }
    });

    let content = data.map((item) => {
      // If the item is a page break, return it as is
      if (item.pageBreak) {
        return item;
      }

      // Otherwise, return the item as a row in a table
      return {
        table: {
          widths: ["*"], // Set the width of each column
          body: [item],
        },
        layout: "noBorders", // Remove the borders from the table
      };
    });

    return {
      info: {
        title: "schools.pdf",
      },
      pageSize: {
        width: 684, //points
        height: 297, //points
      },
      pageMargins: [360, 150, 0, 0],
      content: content,
    };
  };

  const getSchools = async () => {
    const res = await getAllSchoolsSimpleList();
    if (res.data) {
      setSchools(res.data.schools);
    }
  };

  const fetchAndSetSelectedSchools = async (schoolsIds) => {
    const res = await getAllSchools({
      params: {
        pagination: false,
        q: { id_in: schoolsIds },
      },
    });
    if (res.data) {
      // Sort schools array to match the order of schoolsIds.
      // This is necessary to ensure user selected schools are displayed first.
      const sortedSchools = schoolsIds
        .map((id) => res.data.schools.find((school) => school.id === id))
        .filter(Boolean);

      setSelectedSchools(sortedSchools);
    }
  };

  const onSubmit = (data) => {
    setSearchParams((prev) => {
      prev.set(
        "schools_ids",
        data.schools_ids.map((item) => item.id).join(",")
      );
      return prev;
    });
  };

  useEffect(() => {
    if (selectedSchools?.length > 0 && data.length === 0) {
      const pdfDocGenerator = pdfMake.createPdf(docDefinition());
      pdfDocGenerator.getDataUrl((dataUrl) => {
        document.querySelector("#pdfViewer").src = dataUrl;
      });
    }
  }, [selectedSchools]);

  useEffect(() => {
    getSchools();
  }, []);

  useEffect(() => {
    const schoolsIds = searchParams.get("schools_ids")?.split(",") || [];

    if (schoolsIds?.length > 0) {
      fetchAndSetSelectedSchools(schoolsIds);
    }
  }, [searchParams]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="flex flex-row justify-between my-10">
          <p className="w-2/3">
            <b>Step One:</b> Enter school codes to print envelopes for those
            schools. Type the school code or name to find the school and press
            Enter to add it. Edit as many schools as you want.
          </p>
          {selectedSchools?.length > 0 && (
            <Button
              variant="primary"
              onClick={() =>
                pdfMake.createPdf(docDefinition()).download("schools.pdf")
              }
            >
              Download PDF
            </Button>
          )}
        </div>
        {schools?.length > 0 ? (
          <div className="flex flex-row gap-4">
            <div className="w-1/3">
              <MultiSelect
                id="schools_ids"
                placeholder="Schools"
                options={schools.map((school) => ({
                  name: `(#${school.school_code}) ${school.church_name}`,
                  id: school.id,
                }))}
                defaultSelected={
                  selectedSchools?.map((school) => ({
                    name: `(#${school.school_code}) ${school.church_name}`,
                    id: school.id,
                  })) || []
                }
              />
            </div>
            <div className="w-2/3">
              <Button type="submit" variant="primary">
                Show Envelopes
              </Button>
            </div>
          </div>
        ) : (
          <div className="animate-spin rounded-full h-10 w-10 border-b-2 border-secondary" />
        )}

        {selectedSchools?.length > 0 && (
          <iframe id="pdfViewer" className="w-full h-[700px]"></iframe>
        )}
      </form>
    </FormProvider>
  );
}
