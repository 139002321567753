import React, { useEffect, useCallback, useRef } from "react";
import { useForm } from "react-hook-form";
import { XMarkIcon, MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { twMerge } from "tailwind-merge";

export default function QuickFind({
  onSubmit,
  value,
  minLength = 2,
  className,
  dark = false,
  autoFocus = false,
}) {
  const { register, handleSubmit, setValue, watch } = useForm();
  const timeoutRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    if (autoFocus) {
      inputRef.current?.focus();
    }
  }, [autoFocus]);

  useEffect(() => {
    setValue("search", value);
  }, [value, setValue]);

  const searchValue = watch("search");

  const handleClear = () => {
    setValue("search", "");
    handleSubmit(onSubmit)();
  };

  const debouncedSubmit = useCallback(
    (data) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        // Only submit if the search is blank or has 2 or more characters
        if (data.search === "" || data.search.length >= minLength) {
          onSubmit(data);
        }
      }, 500);
    },
    [onSubmit]
  );

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const handleFocus = () => {
    inputRef.current?.focus();
  };

  return (
    <div className="relative">
      <form
        onChange={handleSubmit(debouncedSubmit)}
        onSubmit={(e) => e.preventDefault()}
      >
        <input
          name="search"
          className={twMerge(
            "w-[200px] xl:w-[300px] border-b border-b-black bg-transparent placeholder-black py-[6px] px-2",
            className,
            dark && "text-white placeholder-white border-white"
          )}
          placeholder="Quick find"
          {...register("search")}
          ref={(e) => {
            register("search").ref(e);
            inputRef.current = e;
          }}
        />
        {searchValue ? (
          <XMarkIcon
            className="h-5 w-5 absolute right-2 top-[50%] transform -translate-y-1/2 text-gray-500 cursor-pointer hover:text-gray-900"
            onClick={handleClear}
          />
        ) : (
          <MagnifyingGlassIcon
            className="h-5 w-5 absolute right-2 top-[50%] transform -translate-y-1/2 text-gray-500 cursor-pointer hover:text-gray-900"
            onClick={handleFocus}
          />
        )}
      </form>
    </div>
  );
}
