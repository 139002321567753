import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getAllVersions } from "../../requests/versions";
import PageLoader from "../UI/PageLoader";
import VersionsTable from "./VersionsTable";
import { checkPermission } from "../../utils/checkPermission";
import { useMediaQuery } from "../../utils/useMediaQuery";

export default function RecentChanges({ itemType, itemId }) {
  const isGlobalAdmin = checkPermission(["global_admin"]);
  const isMobile = useMediaQuery("md", "down");

  const [versions, setVersions] = useState();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [directVersionsCount, setDirectVersionsCount] = useState();
  const [allRelatedVersionsCount, setAllRelatedVersionsCount] = useState();

  // Fetch direct versions only to get the total count of pagination
  const countDirectVersions = () => {
    getAllVersions({
      params: {
        per_page: 1,
        q: { item_id_eq: itemId },
      },
    }).then((res) => {
      setDirectVersionsCount(res.data.pagination.total_count);
    });
  };

  const fetchRelatedVersions = () => {
    setLoading(true);

    getAllVersions({
      params: {
        per_page: 5,
        q: { [itemType + "_id_eq"]: itemId },
      },
    })
      .then((res) => {
        setVersions(res.data.versions);
        setAllRelatedVersionsCount(res.data.pagination.total_count);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  useEffect(() => {
    countDirectVersions();
    fetchRelatedVersions();
  }, []);

  // Only Global Admins can see recent changes
  if (isMobile || !isGlobalAdmin) return <></>;

  if (loading) return <PageLoader />;

  return (
    <div className="border-t border-black pt-4 mt-4">
      <h2 className="text-2xl mb-2">Recent changes</h2>

      <Link
        to={`/versions?q[item_id_eq]=${itemId}`}
        className="hover:text-secondary"
      >
        {directVersionsCount || 0} versions
      </Link>

      {" | "}

      <Link
        to={`/versions?q[${itemType}_id_eq]=${itemId}`}
        className="hover:text-secondary"
      >
        {allRelatedVersionsCount || 0} versions (including child records)
      </Link>

      {allRelatedVersionsCount > 0 && (
        <div className="mt-2">
          <button
            className="hover:text-secondary"
            type="button"
            onClick={handleClick}
          >
            {open ? "Hide" : "Show"} last 5 changes (including child records)
            <span className="text-xs ml-2 text-gray-500">►</span>
          </button>
        </div>
      )}

      {open && !loading && versions?.length > 0 && (
        <div className="mt-4">
          <VersionsTable versions={versions} loading={loading} />
        </div>
      )}
    </div>
  );
}
