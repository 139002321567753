import React from "react";
import { useSearchParams } from "react-router-dom";
import { XMarkIcon } from "@heroicons/react/24/outline";
import QuickFind from "../../UI/QuickFind";

// This component is being used currently only for the Students page.
// If we use it in other pages, would be better to create a context for it and let
// the components that use it update the search params.
export default function MobileSearch({ setMobileSearchOpen }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const section = searchParams.get("section") || "all";
  const query = searchParams.get("query") || "";

  const onSubmit = async (data) => {
    setSearchParams((prev) => {
      prev.set("section", section);
      prev.set("page", "1");
      prev.set("query", data.search || "");
      return prev;
    });
  };

  return (
    <div className="px-2 py-3 flex items-center">
      <div className="flex-1 pl-2">
        <QuickFind
          onSubmit={onSubmit}
          value={query}
          className="w-full"
          dark
          autoFocus
        />
      </div>

      <div className="w-12 text-center flex items-center justify-center">
        <button type="button" onClick={() => setMobileSearchOpen(false)}>
          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
    </div>
  );
}
